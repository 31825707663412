import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Courses = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const cutoffScore = data.site.siteMetadata.cutoffScore
  const { title } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${title}  (${totalCount} time${
    totalCount === 1 ? ")" : "s)"
  }`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={tagHeader}
        description={tagHeader}
      />
    <article>
      <h2>{tagHeader}</h2>
      <ul className="tag-template-results">
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { description } = node.frontmatter
          const { date } = node.frontmatter
          const { score } = node.frontmatter
          let scoreSplit = score.split(',',1)
          return (
            <li key={slug}>
              <Link to={slug}>
              <div className="flexcontainer"> 
              {date}
              { description ? <span className="description">({description})</span> : null}
              { !isNaN(parseInt(scoreSplit)) ? <span className={`details ${scoreSplit < cutoffScore ? 'green' : 'red'}`}>{scoreSplit}</span> : null }
              </div>
              </Link>
            </li>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      {/*<Link to="/tags">All tags</Link>*/}
      <hr />
    </article>
    </Layout>
  )
}

export default Courses

export const pageQuery = graphql`
  query($title: String) {
    site {
      siteMetadata {
        title
        cutoffScore
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { title: { in: [$title] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "dddd MMMM DD, YYYY")
            description
            score
          }
        }
      }
    }
  }
`
